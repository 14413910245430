<template>
  <div class="m-top-characteristics">
    <div class="m-product-characteristics">
      <div
        class="m-product-characteristics__row"
        v-for="(attribute, index) in attributesTop"
        :key="index"
      >
        <div class="m-product-characteristics__label">
          {{ attribute.label }}
        </div>
        <ABrandLink
          v-if="attribute.code === 'pim_brand_id'"
          custom-calss="m-product-characteristics__value"
          :prepare-value="prepareValue(attribute)"
          :brand-link="product"
        />
        <div
          v-else
          class="m-product-characteristics__value"
          v-html="prepareValue(attribute)"
        />
      </div>
    </div>
    <SfButton
      v-if="attributesTop.length"
      @click="openDescription"
      class="sf-button--text more-info-button"
    >
      {{ $t("More information about the product") }}
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { createSmoothscroll } from 'theme/helpers'
import { mapGetters } from 'vuex';
import ProductCharacteristics from 'theme/store/product-extension/components/ProductCharacteristics';
import config from 'config';
import escapeHTML from 'escape-html';
import ABrandLink from 'theme/components/atoms/a-brand-link';

export default {
  name: 'MProductTopCharacteristics',
  components: {
    SfButton,
    ABrandLink
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [ProductCharacteristics],
  computed: {
    ...mapGetters({
      characteristicAttributes: 'product-extension/getCharacteristicAttributes',
      productAttributeLabel: 'product-extension/getProductAttributeLabel',
      characteristicAttributeCodes: 'product-extension/getTopCharacteristicAttributeCodes'
    })
  },
  methods: {
    openDescription () {
      const descrEl = document.querySelector('#m-product-additional-info');
      if (!descrEl) return;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      createSmoothscroll(scrollTop, scrollTop + descrEl.getBoundingClientRect().top - 40);
    },
    getLabelUnit (code) {
      return code === config.attributesCodes.weight ? this.$t('g.') : (code === config.attributesCodes.volumeOnPdp ? this.$t('ml.') : null)
    },
    prepareValue (attribute) {
      return attribute.code === 'weight' ? `${escapeHTML(attribute.value)} ${this.getLabelUnit(attribute.code)}` : `${escapeHTML(attribute.value)}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/store/product-extension/css/product-characteristics";

 .m-product-characteristics {
  &__row {
    ::v-deep .m-product-characteristics__value {
      display: flex;

      &--units {
        span {
          padding-left: var(--spacer-5);

          &:empty {
            padding: 0;
          }
        }
      }
    }
  }
}

.m-top-characteristics {
  margin-top: 37px;
}

.characteristic-icon {
  display: inline-block;
  margin-right: 28px;

  &:last-child {
    margin-right: 0;
  }
}

.title {
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

.more-info-button {
  font-weight: normal;
  margin-top: 15px;
  color: var(--orange);
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  --button-text-decoration: none;

  &.sf-button:not(.sf-button--pure):active {
    --button-background: transparent;
    --button-color: var(--orange-pressed);
  }

  @media (max-width: $tablet-max) {
    padding: 0;
  }
}
</style>
