<template>
  <SfCarousel
    class="tm-carousel slider-carousel"
    :settings="sliderSettings"
    :key="componentKey"
  >
    <template #prev="{go}">
      <SfButton
        v-show="sliderPerPage > 1"
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-prev"
        aria-label="previous"
        @click="goPrev(); go('prev')"
        :disabled="sliderData.disablePrev"
        :class="prevButtonClass"
      />
    </template>
    <SfCarouselItem
      v-for="(subcategory, i) in subcategories"
      :key="i"
    >
      <SfLink
        :link="humanUrl(subcategory)"
        class="m-tm-card sf-link--primary"
      >
        <SfImage
          :src="getCategoryPreviewImage(subcategory.category_id)"
          class="m-tm-card__img"
        />
        <div class="m-tm-card__content">
          <div class="m-tm-card__title">
            {{ subcategory.name }}
          </div>
        </div>
      </SfLink>
    </SfCarouselItem>
    <template #next="{go}">
      <SfButton
        v-show="sliderPerPage > 1"
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-next"
        aria-label="next"
        @click="goNext(); go('next')"
        :disabled="sliderData.disableNext"
        :class="nextButtonClass"
      />
    </template>
  </SfCarousel>
</template>
<script>
import { SfLink, SfImage, SfCarousel, SfButton } from '@storefront-ui/vue';
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG } from 'theme/helpers/breakpoints';
import { getCategoryImagePath } from 'theme/helpers';
import { HumanFilter } from 'theme/helpers/humanFilter';
import { mapGetters } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MTmCarousel',
  components: {
    SfLink,
    SfCarousel,
    SfImage,
    SfButton
  },
  props: {
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 5,
          gap: 10,
          dragThreshold: false,
          breakpoints: {
            [BREAKPOINT_LG - 1]: {
              perView: 5
            },
            [BREAKPOINT_MD - 1]: {
              perView: 3.3
            },
            [BREAKPOINT_SM - 1]: {
              perView: 1.3,
              swipeThreshold: false,
              dragThreshold: false
            }
          }
        };
      }
    },
    subcategories: {
      type: Array,
      default: () => ([])
    },
    pimBrandId: {
      type: [Number, String],
      default: () => ''
    }
  },
  data () {
    return {
      componentKey: 1,
      sliderData: {
        currentPage: 1,
        disablePrev: true,
        disableNext: false
      },
      previewSize: {
        width: 200,
        height: 100
      }
    };
  },
  computed: {
    ...mapGetters({
      characteristicAttributes: 'product-extension/getCharacteristicAttributes'
    }),
    sliderPerPage () {
      return Math.ceil(this.subcategories.length / this.sliderSettings.perView)
    },
    prevButtonClass () {
      return {
        'sf-arrow--active': this.sliderData.disableNext
      }
    },
    nextButtonClass () {
      return {
        'sf-arrow--active': !this.sliderData.disableNext
      }
    },
    brand () {
      return ((this.characteristicAttributes || [])?.find(c => c.attribute_code === 'pim_brand_id')?.options || [])
        .find(c => +c.value === +this.pimBrandId)
    }
  },
  watch: {
    subcategories: {
      handler () {
        this.componentKey += 1
      }
    },
    deep: true
  },
  methods: {
    goNext () {
      this.sliderData.currentPage = (this.sliderData.currentPage + 1)
      this.sliderData.disableNext = (this.sliderPerPage === this.sliderData.currentPage)
      this.sliderData.disablePrev = false
    },
    goPrev () {
      this.sliderData.currentPage = (this.sliderData.currentPage - 1)
      this.sliderData.disablePrev = (this.sliderData.currentPage === 1)
      this.sliderData.disableNext = false
    },
    getCategoryPreviewImage (itemId) {
      return getCategoryImagePath(itemId, this.previewSize.width, this.previewSize.height)
    },
    humanUrl (subcategory) {
      const hf = new HumanFilter(localizedRoute(`/${subcategory.url_key}`))

      hf.toggleUri('brand', this.brand?.uri_name)

      return hf.toString()
    }
  },
  mounted () {
    if (this.sliderPerPage === 1) {
      this.sliderData.disableNext = true
      this.sliderData.disablePrev = true
    }
  }
};
</script>

<style lang="scss" scope>
@import 'theme/css/breakpoints';

div.tm-carousel {
  &.slider-carousel {
    .sf-carousel__controls {
      top: var(--carousel-controls-top, 50%);
      pointer-events: none;
      z-index: 1;
      right: 0;
      left: 0;
      margin: 0 calc(-1 * var(--spacer-20)) 0 calc(-1 * var(--spacer-20));
      width: auto;

      .sf-button {
        pointer-events: auto;
        background: var(--white);
        margin: 0;
        opacity: 1;

        &[disabled] {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .glide__slides,
  .glide__track,
  .glide__slide {
    @include for-mobile {
      overflow: auto hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      @-moz-document url-prefix() {
        scrollbar-width: none;
      }
    }
  }
}

.m-tm-card {
  display: block;
  position: relative;
  text-decoration: none;

  .m-tm-card__title {
    font-weight: var(--font-medium);
    color: var(--black);
  }

  &__img {
    position: relative;
    width: 100%;
    padding-bottom: 43.48%;
    background-color: var(--blue-light2);

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--spacer-25) var(--spacer-30);
    pointer-events: none;
  }
}
</style>
