<template>
  <div class="review-form review-form__answer">
    <form>
      <SfInput
        v-model="firstname"
        name="first-name"
        :label="firstNameLabel"
        :valid="!$v.firstname.$error"
        :error-message="$v.firstname.required ? $t('Please use only letters or symbols') : $t('Field is required')"
        class="sf-input--filled review-form__element"
        data-transaction-name="Review - Answer - Field"
        @input="$v.firstname.$touch()"
      />
      <SfInput
        v-model="email"
        name="email"
        :label="$t('E-mail')"
        class="sf-input--filled review-form__element"
        data-transaction-name="Review - Answer - Email"
        :valid="!$v.email.$error"
        :error-message="$v.email.required ? $t('Please provide valid e-mail address.') : $t('Field is required')"
        @input="$v.email.$touch()"
      />
      <ATextarea
        :placeholder="$t('Review text')"
        :alternative-placeholder="$t('Review text')"
        v-model="review"
        :min-height="90"
        type="text"
        :valid="!$v.review.$error"
        :error-message="$t('Field is required')"
        id="review"
        name="review"
        :class="{'custom-input--invalid': $v.review.$error}"
        class="review-form__element"
        data-transaction-name="Review - Answer - Text"
        @input="$v.review.$touch()"
      />
      <div
        v-if="$v.review.$error"
        class="custom-error-message"
      >
        {{$t('Field is required')}}
      </div>

      <SfButton
        class="review-form__submit"
        data-transaction-name="Review - Answer - Submit"
        @click.prevent="submitForm()"
      >
        {{ $t('Send') }}
      </SfButton>
    </form>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import SfInput from 'theme/components/storefront-override/SfInput';
import ATextarea from 'theme/components/atoms/a-textarea';
import { isNameValid } from 'theme/helpers/validation';
import { email, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'MAnswerReviewForm',
  components: {
    SfInput,
    SfButton,
    ATextarea
  },
  data () {
    return {
      email: '',
      firstname: '',
      firstNameLabel: this.$t('First name'),
      review: '',
      reviewStars: 0,
      stars: 5
    };
  },
  validations: {
    email: {
      required,
      email
    },
    firstname: {
      required,
      isNameValid
    },
    review: {
      required
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser'
    })
  },
  mounted () {
    this.useUserInfo();
  },
  methods: {
    activeStars (value) {
      this.reviewStars = value
    },

    useUserInfo () {
      this.email = this.currentUser.email;
      this.firstname = this.currentUser.firstname;
    },

    async submitForm () {
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const data = {
        email: this.email,
        firstname: this.firstname,
        review: this.review
      }
      this.$emit('answerReview', data);
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.review-form {
  margin: 0;

  &__answer {
    margin: var(--spacer-20) 0 var(--spacer-40);
  }

  &__title {
    margin: 0;
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);

    @include for-desktop {
      font-size: var(--font-size-20);
    }
  }

  &-rating {
    display: flex;
    align-items: center;
    margin: var(--spacer-14) 0 var(--spacer-20);

    &__title {
      margin: 0;
      font-size: var(--font-size-13);
      color: var(--dark-gray);
    }

    &-stars {
      margin-left: var(--spacer-10);
      display: flex;
      align-items: center;
      position: relative;

      &--active {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
      }

      ::v-deep {
        .sf-rating__icon {
          cursor: pointer;
          margin: 0 px2rem(1);
        }
      }
    }
  }

  &__element {
    margin-bottom: var(--spacer-10);
    border: none;
    --input-background: var(--light-gray);
    --input-padding: var(--spacer-27) var(--spacer-15) var(--spacer-10);
    --input-font-line-height: var(--spacer-18);
    --input-border: none;
    --input-label-font-size: var(--font-size-15);
    --input-label-font-line-height: var(--font-size-15);
    --input-bar-display: none;
  }

  ::v-deep {
    .a-textarea__clear {
       display: none;
    }

    .a-textarea__field {
      margin-bottom: var(--spacer-10);
      border: 1px solid var(--white);
      background-color: var(--light-gray);
      padding: var(--spacer-20) var(--spacer-15) var(--spacer-10);

      &:focus {
        padding: var(--spacer-30) var(--spacer-15) var(--spacer-10);

        & ~ * {
          font-size: var(--font-size-12);
          top: var(--spacer-10);
        }
      }
    }

    .a-textarea__label {
      font-size: var(--font-size-15);
      top: var(--spacer-23);
    }

    .a-textarea--has-text {
      .a-textarea__label {
        font-size: var(--font-size-12);
        top: var(--spacer-10);
      }

      .a-textarea__field {
        padding: var(--spacer-30) var(--spacer-15) var(--spacer-10);
      }

      .a-textarea__field:focus {
        border: 1px solid var(--orange);
      }
    }

    .sf-input {
      &--has-text {
        .sf-input__label {
          --input-label-top: 23%;
          --input-label-color: var(--dark-gray);
          --input-label-font-size: var(--font-size-12);
        }
      }

      input {
        border: 1px solid var(--white);

        &:focus {
          & ~ * {
            --input-label-top: 23%;
            --input-label-color: var(--dark-gray);
            --input-label-font-size: var(--font-size-12);
          }
        }

        &:focus-visible {
          outline: none;
        }
      }

      &--invalid {
        input {
          border: 1px solid var(--red-main);
        }
      }

      &__error-message {
        height: unset;
      }
    }

    .sf-input--filled input:hover {
      border-color: var(--light-gray);
    }

    .sf-input--filled input:focus {
      border: 1px solid var(--orange);
    }

    .custom-error-message {
      color: var(--input-error-message-color, var(--c-danger));
      height: calc(var(--font-xs) * 1.2);
      font: var(--input-error-message-font, var(--input-error-message-font-weight, var(--font-medium)) var(--input-error-message-font-size, var(--font-xs))/var(--input-error-message-font-line-height, 1.2) var(--input-error-message-font-family, var(--font-family-secondary)));
    }
    .custom-input--invalid {
      .a-textarea__field {
        border: 1px solid var(--red-main);
      }
    }
  }

  &__submit {
    width: 100%;
    margin-top: var(--spacer-20);
    font-size: var(--font-size-18);
    line-height: var(--spacer-24);

    @include for-desktop {
      width: auto;
    }

    &:hover {
      background-color: var(--orange-hover);
    }

    &:disabled {
      pointer-events: none;
    }
  }
}

</style>
