import { render, staticRenderFns } from "./badges.vue?vue&type=template&id=d727aae8&scoped=true"
import script from "./badges.vue?vue&type=script&lang=js"
export * from "./badges.vue?vue&type=script&lang=js"
import style0 from "./badges.vue?vue&type=style&index=0&id=d727aae8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d727aae8",
  null
  
)

export default component.exports